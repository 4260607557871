import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  profileForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', { validators: [Validators.email] }),
    phone: new FormControl('', { validators: [Validators.pattern('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$')] }),
    message: new FormControl(''),
  });
  value = 'Clear me';

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  submitForm(): void {
    const values = this.profileForm.value;
    if (this.profileForm.controls.email.invalid) { alert(`Form Contains following errors:\nEmail is invalid`); return; }
    if (!values.email && !values.phone) { alert('Form Contains following errors:\nNo contact information given, please fill in Phone Number or Email'); return; }
    if (this.profileForm.controls.phone.invalid) { alert(`Form Contains following errors:\nPhone Number should be given in one of the following formats:\n- 123-456-7890\n- (123) 456-7890\n- 123 456 7890\n- 123.456.7890\n- +91 (123) 456-7890`); return; }

    this.sendEmail(values);
  }

  sendEmail(values): void {
    const formattedValues: any = {};
    if (values.firstName) { formattedValues.firstName = values.firstName; }
    if (values.lastName) { formattedValues.lastName = values.lastName; }
    if (values.email) { formattedValues.email = values.email; }
    if (values.phone) { formattedValues.phone = values.phone; }
    if (values.message) { formattedValues.message = values.message; }

    const header: HttpHeaders = new HttpHeaders();
    header.append('Accept', 'application/json');

    this.http.get(`${environment.lambdaUrl}`, {params: formattedValues}).subscribe(
      (res) => { this.profileForm.reset(); alert('Message Sent Successfully!'); },
      (err) => { this.profileForm.reset(); alert('Message Sent Successfully!'); }
    );
  }
}
