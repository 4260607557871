<div class="header-container"
     [ngClass]="{ 'fixed-nav' : fixHeader }"
     *ngIf="!toggle && !alternativeHeader">
  <div class="upper-container"
       (scroll)="onScroll()">
    <div class="header-logo">
      <img src="/assets/logo.svg" alt="" class="logo">
      <img src="/assets/icons/menu-icon.svg" alt="" class="menu-icon" (click)="openMobileMenu()">
    </div>
    <div class="header-navigation">
      <a href="" routerLink="/home" (click)="pageTitleSelect('Home')"><span class="navigation-btn-text">Home</span></a>
      <a href="" routerLink="/about" (click)="pageTitleSelect('About us')"><span class="navigation-btn-text">About us</span></a>
      <a href="" routerLink="/portfolio" (click)="pageTitleSelect('Portfolio')"><span class="navigation-btn-text">Portfolio</span></a>
      <a href="" routerLink="/contact" (click)="pageTitleSelect('Contact')"><span class="navigation-btn-text">Contact</span></a>
    </div>
  </div>
  <span class="mti-h1">{{currentPageTitle}}</span>
</div>
<div class="mobile-menu" *ngIf="toggle && !alternativeHeader">
  <div class="mobile-navigation">
    <img src="/assets/icons/menu-icon.svg" alt="" class="menu-icon" (click)="openMobileMenu()">
    <a href="" routerLink="/home" (click)="pageTitleSelect('Home')"><span class="navigation-btn-text">Home</span></a>
    <a href="" routerLink="/about" (click)="pageTitleSelect('About us')"><span class="navigation-btn-text">About us</span></a>
    <a href="" routerLink="/portfolio" (click)="pageTitleSelect('Portfolio')"><span class="navigation-btn-text">Portfolio</span></a>
    <a href="" routerLink="/contact" (click)="pageTitleSelect('Contact')"><span class="navigation-btn-text">Contact</span></a>
  </div>
  <div class="information-container">
    <div class="phone-container container">
      <img src="/assets/icons/phone.svg" alt="" class="phone icon">
      <div class="phones">
        <span class="mti-h3">+1 (437) 970-4105 <br> (English) <br><br> +1 (437) 970-4105 <br> (Polish,Ukrainian)</span>
      </div>
    </div>
    <div class="email-container container">
      <img src="/assets/icons/email.svg" alt="" class="email icon">
      <div class="emails">
        <span class="mti-h3">Megamaster2022ink@gmail.com</span>
      </div>
    </div>
    <div class="address-container container">
      <img src="/assets/icons/location.svg" alt="" class="address icon">
      <span class="mti-h3">2545 KINGSBERRY CRES <br> MISSISSAUGA ON L5B 2K7 <br> CANADA</span>
    </div>
  </div>
</div>

<app-alternative-header (alternativeHeader)="closeMenu($event)" *ngIf="alternativeHeader"></app-alternative-header>
