import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  isHeaderFixed = false;
  currentPage: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.currentPage = location.pathname.replace('/', '');
    });
  }

  fixedHeader(value) {
    this.isHeaderFixed = value;
  }
}
