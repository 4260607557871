import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ABout } from '../../../data-models/AboutMock';

@Component({
  selector: 'app-google-page-remodeling',
  templateUrl: './google-page-remodeling.component.html',
  styleUrls: ['./google-page-remodeling.component.scss']
})
export class GooglePageRemodelingComponent implements OnInit {
  aboutImg = ABout;
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Bathroom Remodeling in Toronto with Megamaster Tile: Tips and Ideas');
    this.metaTagService.updateTag({ name: 'description', content: 'Looking to remodel your bathroom in Toronto with high-quality Megamaster Tile? Read our guide for tips, ideas, and common mistakes to avoid for a successful renovation project.' });
  }

}
