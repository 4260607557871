<div class="contact-container">
  <img src="/assets/brick-right-small.svg" alt="" class="bricks-img">
  <span class="mti-subtitle">Considering a renovation or a remodel?</span>
  <span class="mti-h4">If you would like to learn more about the cost to build or renovate your home,
    please contact us, we are always happy to talk.</span>
  <div class="information-container">
    <div class="phone-container container">
      <img src="/assets/icons/phone.svg" alt="" class="phone">
      <div class="phones">
        <span class="mti-h3">+1 (437) 970-4105 <br> (English) <br> +1 (416) 720-0510 <br> (Polish,Ukrainian)</span>
      </div>
    </div>
    <div class="email-container container">
      <img src="/assets/icons/email.svg" alt="" class="email">
      <div class="emails">
        <span class="mti-h4">Megamaster2022ink@gmail.com</span>
      </div>
    </div>
    <div class="address-container container">
      <img src="/assets/icons/location.svg" alt="" class="address">
      <span class="mti-h3">2545 KINGSBERRY CRES <br> MISSISSAUGA ON L5B 2K7 <br> CANADA</span>
    </div>
  </div>
  <div class="contact-form" [formGroup]="profileForm">
    <span class="mti-h4">You can also use this form and we will get back to you as soon as we can:</span>
    <div class="name-and-lastname-container">
      <input placeholder="First Name" type="text" class="input-container name" formControlName="firstName">
      <input placeholder="Last Name" type="text" class="input-container surname" formControlName="lastName">
    </div>
    <div class="phone-and-email-container">
      <input placeholder="Phone number" type="text" class="input-container phone" formControlName="phone">
      <input placeholder="e-mail" type="email" class="input-container email" formControlName="email">
    </div>
    <mat-form-field>
      <textarea matInput placeholder="Text here..." class="text-area" style="resize: none;" formControlName="message"></textarea>
    </mat-form-field>
    <div class="button-container">
      <button class="submit-btn" (click)="submitForm()"><img src="/assets/arrow.svg" alt="" ><span class="mti-h4">Submit</span></button>
    </div>
  </div>
</div>
