import { Portfolio } from './Portfolio';

export const PortfolioMock: Portfolio[] = [
  {
    id: 1,
    src: [
      '/assets/profile/portfolio-1-1.jpg',
      '/assets/profile/portfolio-1-2.jpg',
      '/assets/profile/portfolio-1-3.jpg',
      '/assets/profile/portfolio-1-4.jpg',
      '/assets/profile/portfolio-1-5.jpg',
    ]
  },
  {
    id: 2,
    src: [
      '/assets/profile/portfolio-2-1.jpg',
      '/assets/profile/portfolio-2-2.jpg',
      '/assets/profile/portfolio-2-3.jpg',
      '/assets/profile/portfolio-2-4.jpg',
      '/assets/profile/portfolio-2-5.jpg',
    ]
  },
  {
    id: 4,
    src: [
      '/assets/profile/portfolio-4-1.jpg',
      '/assets/profile/portfolio-4-2.jpg',
      '/assets/profile/portfolio-4-3.jpg',
      '/assets/profile/portfolio-4-4.jpg',
      '/assets/profile/portfolio-4-5.jpg',
    ]
  },
  {
    id: 5,
    src: [
      '/assets/profile/portfolio-5-1.jpg',
      '/assets/profile/portfolio-5-2.jpg',
      '/assets/profile/portfolio-5-3.jpg',
      '/assets/profile/portfolio-5-4.jpg',
      '/assets/profile/portfolio-5-5.jpg',
    ]
  },  {
    id: 6,
    src: [
      '/assets/profile/portfolio-6-1.jpg',
      '/assets/profile/portfolio-6-2.jpg',
      '/assets/profile/portfolio-6-3.jpg',
      '/assets/profile/portfolio-6-4.jpg',
      '/assets/profile/portfolio-6-5.jpg',
      '/assets/profile/portfolio-6-6.jpg',
    ]
  },
];
