<div class="home-container">
  <div class="first-chapter">
    <div class="brick-background"></div>
    <div class="content-container">
      <div class="image-container">
        <img src="{{homeImg[0].src}}" alt="">
      </div>
      <div class="what-we-do-container">
        <span class="mti-subtitle">What We Do</span>
        <span class="mti-h4">Our residential projects include new bathroom installation as well as restoration of already installed bathrooms,
          hardwood, tiles, natural stone, and more. Regardless of the state of job sites, occupied or fully operational,
          our team is dedicated to fulfilling all of your needs.</span>
        <div class="more-button-container">
          <button class="submit-btn" routerLink="/about" (click)="removeHomeHeader()"><img src="/assets/arrow.svg" alt="" ><span class="mti-h4">more</span></button>
        </div>
      </div>
    </div>
  </div>
  <div class="second-chapter">
    <div class="portfolio-text-container">
      <span class="mti-subtitle">Portfolio</span>
      <span class="mti-h4">A showcase of a wide variety of our completed projects, demonstrating our commitment to quality and attention to detail.</span>
      <div class="more-button-container">
        <button class="submit-btn" routerLink="/portfolio" (click)="removeHomeHeader()"><img src="/assets/arrow.svg" alt="" ><span class="mti-h4">more</span></button>
      </div>
    </div>
    <div class="image-container">
      <img src="{{homeImg[1].src}}" alt="">
    </div>
  </div>
  <div class="third-chapter">
    <div class="brick-background">
    </div>
    <img src="{{homeImg[2].src}}" alt="" class="third-chapter-image">
    <span class="mti-h4">Megamaster has a combined experience of over 10 years.
      Apart of our excelled expertise, we also offer design services to help you create a custom plan that fits your unique style and most importantly budget.
      We use latest technology and techniques to bring your vision to life, while also ensuring that follow exceptional standards of contact signage and deposit collection.</span>
  </div>

  <div class="fourth-chapter">
    <div class="advantages-container" *ngFor="let advantage of advantages; let i = index">
      <img src="{{advantage.src}}" alt class="advantage-image">
      <span class="mti-h4">{{advantage.description}}</span>
    </div>
  </div>
  <div class="hidden-seo-content">
    <span routerLink="/bathroom-renovation-toronto-on">Renovation</span>
    <span routerLink="/tile-installer-toronto-on">Installer</span>
    <span routerLink="/bathroom-remodeling-toronto-on">Remodeling</span>
    <a target="_blank" href="https://maps.app.goo.gl/rMqFc8HEkqF9e3Fn8?g_st=iw">Google maps Info</a>
    <a target="_blank" href="https://m.facebook.com/profile.php?id=100090229075134&eav=AfYKdUZ7YqT-7TxC3XaUqMbYXaSyeJ_YlN4wQ7-jQA5jqKpp2Y5iY3ZaGOxaWQ-PG2Q&ref=content_filter&paipv=0">Facebook</a>
  </div>
</div>
