import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './layout/components/contact/contact.component';
import { AboutUsComponent } from './layout/components/about-us/about-us.component';
import { PortfolioComponent } from './layout/components/portfolio/portfolio.component';
import { HomeComponent } from './layout/components/home/home.component';
import {
  GooglePageRemodelingComponent
} from './layout/components/google-page-remodeling/google-page-remodeling.component';
import {
  GooglePageInstallerComponent
} from './layout/components/google-page-installer/google-page-installer.component';
import {
  GooglePageRenovationComponent
} from './layout/components/google-page-renovation/google-page-renovation.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'bathroom-remodeling-toronto-on', component: GooglePageRemodelingComponent },
  { path: 'tile-installer-toronto-on', component: GooglePageInstallerComponent },
  { path: 'bathroom-renovation-toronto-on', component: GooglePageRenovationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
