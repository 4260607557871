import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  apiLoaded: Observable<boolean>;
  markers: any[] = []; // google maps markers

  constructor(httpClient: HttpClient) {
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyATzRP7GxCzlzzNYJyhRzYzkzSJZSQSSCk', 'callback')
    .pipe(
      map(() => { console.log('shit'); return true; } ),
      catchError((e) => { console.log(e); return of(false); }),
    );

    this.markers.push({
      position: {
        lat: 49.276776434137105,
        lng: -84.8116838123823,
      },
      label: {
        color: 'red',
        text: 'Megamaster Tile'
      },
      title: 'Megamaster Tile',
      options: { animation: google.maps.Animation.BOUNCE },
    });
  }

  ngOnInit() {
  }

}
