import { Component } from '@angular/core';
import { ABout } from '../../../data-models/AboutMock';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  aboutImg = ABout;
}
