<div class="portfolio-container">
  <div class="preview-portfolio-container">
    <div class="swiper">
      <div class="swiper-wrapper">
        <img *ngFor="let profileMock of portfolioMock; let i = index"
             [ngClass]="{'active-slide' : i !== activeSlideIndex}"
             src="{{portfolioMock[i].src[0]}}"
             alt=""
             class="swiper-slide">
      </div>
    </div>
    <div class="swiper-button-prev js-disabled"><img src="/assets/icons/swipe-left.svg" alt=""></div>
    <div class="swiper-button-next js-disabled"><img src="/assets/icons/swipe-right.svg" alt=""></div>
  </div>
  <div class="portfolio-photons-container">
    <img *ngFor="let profileMock of portfolioMock[activeSlideIndex || 0].src; let i = index"
       src="{{portfolioMock[activeSlideIndex || 0].src[i]}}"
       class="portfolio-photo"
       alt="">
  </div>
</div>
