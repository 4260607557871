import { ShellComponent } from './containers/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { ContactComponent } from './components/contact/contact.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { AlternativeHeaderComponent } from './components/header/alternative-header/alternative-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GooglePageRenovationComponent } from './components/google-page-renovation/google-page-renovation.component';
import { GooglePageRemodelingComponent } from './components/google-page-remodeling/google-page-remodeling.component';
import { GooglePageInstallerComponent } from './components/google-page-installer/google-page-installer.component';
import { GoogleMapsModule } from '@angular/google-maps';

export const COMPONENTS = [
  ShellComponent,
  HeaderComponent,
  FooterComponent
];

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleMapsModule,
    HttpClientJsonpModule
  ],
  declarations: [
    COMPONENTS,
    ContactComponent,
    AboutUsComponent,
    PortfolioComponent,
    HomeComponent,
    AlternativeHeaderComponent,
    GooglePageRenovationComponent,
    GooglePageRemodelingComponent,
    GooglePageInstallerComponent
  ],
  exports: COMPONENTS,
  entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule {}
