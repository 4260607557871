<div class="about-us-container">
  <div class="first-chapter">
    <img src="/assets/bricks-right-about-us.svg" alt="" class="bricks-right-about-us">
    <div class="image-container">
      <img src="{{aboutImg[0].src}}" alt="" class="about-us-img">
    </div>
    <span class="mti-h4">Our company of experts with an extensive list of specialities is dedicated to delivering comprehensive renovation services, including remodelling, design and much more. We are committed to our clients and ensure who we work closely with to turn their visions into a reality, ensuring a top-notch attention to detail and quality of every single project. Trust us to elevate your home’s overall value and bring new life to your bathroom.</span>
  </div>
  <div class="second-chapter">
    <div class="left-container">
      <div class="text-container">
        <span class="mti-subtitle">Renovation vs remodel</span>
        <span class="mti-h4">It is important to know your goals. We will provide you with all the pros and cons of both renovation and remodelling and let you decide which is best for you and your home.</span>
      </div>
    </div>
    <div class="right-container">
      <img src="{{aboutImg[1].src}}" alt="" class="about-us-img">
    </div>
  </div>
  <div class="third-chapter">
    <div class="right-container">
    </div>
    <div class="left-container">
      <div class="text-container">
        <span class="mti-subtitle">Renovation</span>
        <span class="mti-h4">When deciding to renovate, it is important to keep in mind that it refers to a process of improving or updating the existing structure. It involves fixing, restoring, or modernizing already existing features to improve looks as well as functionality. This might include simple cosmetic updates such as painting and replacing fixtures. Our goal with renovation is to improve the overall comfort, functionality, and value of property, while preserving its character and in some cases historical significance.</span>
      </div>
    </div>
    <img src="{{aboutImg[2].src}}" alt="" class="about-us-img">
  </div>
  <div class="fourth-chapter">
    <div class="left-container">
      <div class="text-container">
        <span class="mti-subtitle">Remodel</span>
        <span class="mti-h4">If making major changes or additions to the design or structure is what you are considering, then remodelling is what you might be looking into. Remodelling often involves changing the layout, adding or removing walls, installing new flooring. Our remodelling service offers a complete solution for your home needs. From planning and design to constriction and final touches. We use only best materials and employ the latest techniques to ensure your remodel exceeds your expectations.</span>
      </div>
    </div>
    <div class="right-container">
      <img src="{{aboutImg[3].src}}" alt="" class="about-us-img">
    </div>
    <div class="brick-background">
      <img src="/assets/bricks-left-about-us.svg" alt="" class="bricks-left-about-us">
    </div>
  </div>
  <div class="fifth-chapter">
    <img src="{{aboutImg[4].src}}" alt="" class="about-us-img">
    <span class="mti-h4">Whether you are considering remodeling your home or renovating specific areas, our team at Megamaster has the expertise and dedication to fulfill your vision into a reality. With our comprehensive renovation and remodeling services, rest assured that every aspect of your project will be handled with the utmost attention to detail. From the initial design until the final touches, we will be there with you every step of the way. If you are ready to transform your space and elevate your lifestyle, contact us for a consultation today. Our team is very eager to work with you in order to turn your ideas into a beautiful and long-lasting reality.</span>
  </div>
</div>
