import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerHeight = 380;
  currentPageTitle: string;
  toggle = false;
  fixHeader = false;

  @Output() fixedNavBar = new EventEmitter<boolean>(false);

  alternativeHeader = false;

  constructor(private router: Router) {
    // @ts-ignore
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getPageTitleOnLoad();
      }
    });
  }

  ngOnInit() {
    this.getPageTitleOnLoad();
    this.getPageUrl();
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    const value = window.scrollY > this.headerHeight;
    this.fixHeader = value;
    this.fixedNavBar.emit(value);
  }

  closeMenu(value) {
    this.alternativeHeader = value;
  }

  pageTitleSelect(title: string) {
    this.currentPageTitle = title;
    this.toggle = false;

    if (title === 'Home') {
      this.alternativeHeader = true;
    }
  }

  getPageTitleOnLoad() {
    const title = location.pathname.replace('/', '');
    if (title.length > 15) { return ; }

    if (title === 'about') {
      return this.currentPageTitle = 'About us';
    } else if (title === 'home') {
      this.alternativeHeader = true;
    } else {
      this.currentPageTitle = title.charAt(0).toUpperCase() + title.slice(1);
    }
  }

  getPageUrl() {
    const title = location.pathname.replace('/', '');
    this.alternativeHeader = title === 'home';
  }

  openMobileMenu() {
    this.toggle = !this.toggle;
  }
}
