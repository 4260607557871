import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ABout } from '../../../data-models/AboutMock';

@Component({
  selector: 'app-google-page-installer',
  templateUrl: './google-page-installer.component.html',
  styleUrls: ['./google-page-installer.component.scss']
})
export class GooglePageInstallerComponent implements OnInit {
  aboutImg = ABout;
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Professional Tile Installation in Toronto | Megamaster Tile');
    this.metaTagService.updateTag({ name: 'description', content: 'Trust Megamaster Tile for expert tile installation services in Toronto. Our skilled installers and high-quality products ensure a flawless finished product for any space in your home. Contact us today for a consultation.' });
  }

}
