import { Component, EventEmitter, Output } from '@angular/core';
import { Home } from '../../../data-models/HomeMock';
import { AdvantagesMock } from '../../../data-models/AdvantagesMock';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  homeImg = Home;
  advantages = AdvantagesMock;

  @Output() alternativeHeader = new EventEmitter<boolean>();

  removeHomeHeader() {
    this.alternativeHeader.emit(false);
  }
}
