import { Component, OnInit } from '@angular/core';
import { PortfolioMock } from '../../../data-models/ProfileMock';
import { Swiper } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  portfolioMock = PortfolioMock;
  activeSlideIndex = 0;

  ngOnInit(): void {
    this.initSwiper();
  }

  initSwiper() {
    const swiper = new Swiper('.swiper', {
      centeredSlides: true,
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 20,
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
        disabledClass: 'js-disabled',
      },
    });
    (swiper[1] ? swiper[1] : swiper).on('transitionEnd', () => {
      this.activeSlideIndex = (swiper[1] ? swiper[1] : swiper).realIndex;
    });
  }
}
