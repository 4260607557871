import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlternativeHeaderMock } from '../../../../data-models/AlternativeHeaderMock';
import { Swiper } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { NavigationEnd, Router } from '@angular/router';
SwiperCore.use([Navigation]);

@Component({
  selector: 'app-alternative-header',
  templateUrl: './alternative-header.component.html',
  styleUrls: ['./alternative-header.component.scss']
})
export class AlternativeHeaderComponent implements OnInit {

  alternativeHeaderMock = AlternativeHeaderMock;
  activeSlideIndex: number;
  menu: boolean;
  homeRoute: boolean;

  @Output() alternativeHeader = new EventEmitter<boolean>();

  constructor(private router: Router) {
    // @ts-ignore
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url !== '/home') {
          this.alternativeHeader.emit(false);
        }
      }
    });
  }

  ngOnInit() {
    this.menu = false;
    this.initSwiper();
  }

  initSwiper() {
    const swiper = new Swiper('.swiper', {
      observer: true,
      slidesPerView: 'auto',
      navigation: {
        prevEl: '.swiper-button-prev-image',
        nextEl: '.swiper-button-next-image',
        disabledClass: 'js-disabled',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
    });

    swiper.on('transitionEnd', () => {
      this.activeSlideIndex = swiper.realIndex + 1;
    });
  }

  openMenu() {
    this.menu = !this.menu;
  }

  closeMenu(value?: string) {
    this.menu = false;
    if (value === 'home' && !this.homeRoute) {
      this.alternativeHeader.emit(true);
    } else {
      this.alternativeHeader.emit(false);
    }
  }
}
