<div class="footer-container">
  <div class="information-container">
    <div class="left-container">
      <img src="/assets/logo.svg" alt="" class="logo">
      <span class="tile-inc">Megamaster Tile Inc.</span>
    </div>
    <div class="middle-container container">
      <div class="email-container container">
        <img src="/assets/icons/email.svg" alt="" class="email">
        <div class="emails-container">
          <span class="mti-h4">Megamaster2022ink@gmail.com</span>
        </div>
      </div>
      <div class="address-container">
        <img src="/assets/icons/location.svg" alt="" class="address">
        <span class="mti-h3">2545 KINGSBERRY CRES <br> MISSISSAUGA ON L5B 2K7 <br> CANADA</span>
      </div>
    </div>
    <div class="right-container">
      <div class="phone-number-container container">
        <img src="/assets/icons/phone.svg" alt="" class="phone">
        <span class="mti-h3">+1 (437) 970-4105 <br> (English) <br><br> +1 (416) 720-0510 <br> (Polish,Ukrainian)</span>
      </div>
    </div>
  </div>
  <div class="rights-container">
    <div class="map-container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10655925.559623715!2d-84.7384375!3d49.3039745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e4756226d7519%3A0xc8ae1b0941c81a36!2sMegamaster%20Tile!5e0!3m2!1sen!2sus!4v1680201598024!5m2!1sen!2sus"
        width="610"
        height="200"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>

    <span class="mti-h4">All Rights Reserved</span>
  </div>
</div>
