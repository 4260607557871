import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ABout } from '../../../data-models/AboutMock';

@Component({
  selector: 'app-google-page-renovation',
  templateUrl: './google-page-renovation.component.html',
  styleUrls: ['./google-page-renovation.component.scss']
})
export class GooglePageRenovationComponent implements OnInit {
  aboutImg = ABout;
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Ultimate Guide to Bathroom Renovation in Toronto with Megamaster Tile');
    this.metaTagService.updateTag({ name: 'description', content: 'Looking to renovate your bathroom in Toronto? Check out our ultimate guide to bathroom renovation with Megamaster Tile. From planning to maintenance, we cover everything you need to know to create a beautiful and functional bathroom.' });
  }

}
